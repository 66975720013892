const ptMessages = {
    "grid.groupPanelEmpty": "Arraste um cabeçalho de coluna e solte aqui para agrupar por essa coluna",
    "grid.pagerItemsPerPage": "itens por página",
    "grid.pagerInfo": "{0} - {1} de {2} itens",
    "grid.pagerFirstPage": "Ir para a primeira página",
    "grid.pagerPreviousPage": "Ir para a página anterior",
    "grid.pagerNextPage": "Ir para a próxima página",
    "grid.pagerLastPage": "Ir para a última página",
    "grid.pagerPage": "Página",
    "grid.pagerOf": "de",
    "grid.pagerTotalPages": "{0}",
    "grid.pagerItemPerPage": "itens por página",
    "grid.filterClearButton": "Limpar",
    "grid.filterEqOperator": "É igual a",
    "grid.filterNotEqOperator": "Não é igual a",
    "grid.filterIsNullOperator": "É nulo",
    "grid.filterIsNotNullOperator": "Não é nulo",
    "grid.filterIsEmptyOperator": "Está vazio",
    "grid.filterIsNotEmptyOperator": "Não está vazio",
    "grid.filterStartsWithOperator": "Começa com",
    "grid.filterContainsOperator": "Contém",
    "grid.filterNotContainsOperator": "Não contém",
    "grid.filterEndsWithOperator": "Termina com",
    "grid.filterGteOperator": "É maior ou igual a",
    "grid.filterGtOperator": "É maior que",
    "grid.filterLteOperator": "É menor ou igual a",
    "grid.filterLtOperator": "É menor que",
    "grid.filterIsTrue": "É verdadeiro",
    "grid.filterIsFalse": "É falso",
    "grid.filterBooleanAll": "(Todos)",
    "grid.filterAfterOrEqualOperator": "É depois ou igual a",
    "grid.filterAfterOperator": "É depois",
    "grid.filterBeforeOperator": "É antes",
    "grid.filterBeforeOrEqualOperator": "É antes ou igual a",
    "grid.filterSubmitButton": "Filtrar",
    "grid.filterAndLogic": "E",
    "grid.filterOrLogic": "Ou",
    "grid.filterTitle": "Filtrar",
    "grid.filterChooseOperator": "Escolha o Operador",
    "grid.filterSelectedItems": "itens selecionados",
    "grid.filterAriaLabel": "Filtro",
    "grid.sortAscending": "Ordenar Ascendente",
    "grid.sortDescending": "Ordenar Descendente",
    "grid.sortAriaLabel": "Ordenável",
    "grid.searchPlaceholder": "Buscar",
    "grid.noRecords": "",
    "grid.filterCheckAll": "Marcar Todos",
    "grid.groupColumn": "Coluna de Grupo",
    "grid.groupExpand": "Expandir grupo",
    "grid.groupCollapse": "Colapsar Grupo",
    "grid.groupPanelAriaLabel": "Painel de grupo",
    "grid.gridAriaLabel": "Tabela",
    "grid.ungroupColumn": "Desagrupar Coluna",
    "grid.detailExpand": "Expandir linha de detalhe",
    "grid.detailCollapse": "Colapsar linha de detalhe"
};

export default ptMessages;