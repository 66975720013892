import React from 'react';
import { showMessage } from '../services/languages/_showmessages';
import shareIcon from '../assets/ShareIcon.svg';
import whatsappIcon from '../assets/whatsappIcon.svg';
import instagramIcon from '../assets/InstagramIcon.svg';
import facebookIcon from '../assets/FacebookIcon.svg';
import twitterIcon from '../assets/TwitterIcon.svg';
import linkedinIcon from '../assets/LinkedinIcon.svg';
import { Notification, NotificationGroup } from '@progress/kendo-react-notification';
import { Fade } from '@progress/kendo-react-animation';

const SharedSocialButtons: React.FC = () => {
  const [showNotification, setShowNotification] = React.useState(false);
  const paths : string[] = window.location.pathname.split("/");
  const pageid = (paths[2] ?? "") === "pageid" ? "/pageid" + "/" + (paths[3] ?? "") : "";
  const url : string = window.location.origin + "/" + paths[1] + pageid;

  const copy2clipboard = () => {
    navigator.clipboard.writeText(url);
    setShowNotification(true);
    setTimeout(()=>{setShowNotification(false)},2000)
  };

  return (
    <div className='shareButtonsDiv'>
      <div className='shareButtonDiv' title={showMessage("copypageurl")}>
        <img src={shareIcon} className='shareSvgIcon' alt="share" onClick={() => {copy2clipboard()}} />
      </div>
      <div className='shareButtonDiv'>
        <a title={showMessage("compartilharNo") + ' WhatsApp'} href={`https://www.addtoany.com/add_to/whatsapp?linkurl=${url}&amp;linkname=DOCPRO`} target='_blank' rel='noopener noreferrer'>
          <img src={whatsappIcon} className='shareSvgIcon' alt="whatsapp" />
        </a>
      </div>
      <div className='shareButtonDiv'>
        <a title={showMessage("compartilharNo") + '  Instagram'} href={`https://www.addtoany.com/add_to/instagram?linkurl=${url}&amp;linkname=DOCPRO`} target='_blank' rel='noopener noreferrer'>
          <img src={instagramIcon} className='shareSvgIcon' alt="instagram" />
        </a>
      </div>
      <div className='shareButtonDiv'>
        <a title={showMessage("compartilharNo") + ' Facebook'} href={`https://www.addtoany.com/add_to/facebook?linkurl=${url}&amp;linkname=DOCPRO`} target='_blank' rel='noopener noreferrer'>
          <img src={facebookIcon} className='shareSvgIcon' alt="facebook" />
        </a>
      </div>
      <div className='shareButtonDiv'>
        <a title={showMessage("compartilharNo") + ' LinkedIN'} href={`https://www.addtoany.com/add_to/linkedin?linkurl=${url}&amp;linkname=DOCPRO`} target='_blank' rel='noopener noreferrer'>
          <img src={linkedinIcon} className='shareSvgIcon' alt="linkedin" />
        </a>
      </div>
      <div className='shareButtonDiv'>
        <a title={showMessage("compartilharNo") + ' X'}  href={`https://www.addtoany.com/add_to/twitter?linkurl=${url}&amp;linkname=DOCPRO`} target='_blank' rel='noopener noreferrer'>
          <img src={twitterIcon} className='shareSvgIcon' alt="twitter" />
        </a>
      </div>

      <NotificationGroup style={{backgroundColor: '#b51e26ce', top: '100px', left: '50%' }}>
        <Fade>
          {showNotification && <Notification type={{style: 'info', icon: false}} closable={false} >
            <span>{showMessage("copiedurl")}</span>
          </Notification>}
        </Fade>
      </NotificationGroup>
    </div>
  );
};

export default SharedSocialButtons;