import React, { useContext, ReactNode } from 'react';
import { BookmarksModel } from '../models/bookmarksModel';

// Definindo o tipo para o contexto
interface BookmarksContextType {
  Bookmarks: BookmarksModel[] | null;
  setBookmarks: React.Dispatch<React.SetStateAction<BookmarksModel[] | null>>;
}

// 1. Crie um novo contexto com tipo definido
const BookmarksContext = React.createContext<BookmarksContextType | undefined>(undefined);

// Definindo o tipo para as props do provedor
interface BookmarksContextProviderProps {
  children: ReactNode;
}

// 2. Crie um provedor de contexto
export function BookmarksContextProvider({ children }: BookmarksContextProviderProps) {  
  const [Bookmarks, setBookmarks] = React.useState<BookmarksModel[] | null>(null);

  return (
    <BookmarksContext.Provider value={{ Bookmarks, setBookmarks }}>
      {children}
    </BookmarksContext.Provider>
  );
}

// 3. Crie um hook personalizado para usar o contexto
export function useBookmarksContext(): BookmarksContextType {
  const context = useContext(BookmarksContext);
  if (context === undefined) {
    throw new Error('useBookmarks must be used within a BookmarksContextProvider');
  }
  return context;
}