import { ItemRenderProps, TreeView, TreeViewExpandChangeEvent, TreeViewItemClickEvent, processTreeViewItems} from '@progress/kendo-react-treeview';
import { folderIcon, folderOpenIcon, filterIcon, xIcon, folderAddIcon, folderMoreIcon } from '@progress/kendo-svg-icons';
import { SvgIcon } from '@progress/kendo-react-common';
import React, { useEffect } from 'react';
import { FoldersModel } from '../models/foldersModel';
import { useFoldersContext } from '../context/FoldersContext';
import { useFolderContext } from '../context/FolderContext';
import { usePageContext } from '../context/PageContext';
import { usePageLoadContext } from '../context/PageLoadContext';
import { TelerikTreeModel } from '../models/telerikTreeModel';
import { Input } from '@progress/kendo-react-inputs';
import { findFolderById, findBookmarkInFolder } from '../services/shared'; 
import { useSearchContext } from '../context/SearchContext';
import { isMobile } from 'react-device-detect';
import { showMessage } from '../services/languages/_showmessages';
import { useLibraryContext } from '../context/LibraryContext';
import { useThumbsLoadContext } from '../context/ThumbsLoadContext';
import { useBookmarksContext } from '../context/BookmarksContext';

interface FoldersTreeviewProps {
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const FoldersTreeview: React.FC<FoldersTreeviewProps> = ({ isVisible, setIsVisible }) => {
  const { Folders } = useFoldersContext();
  const { Folder, setFolder } = useFolderContext();
  const { setPage } = usePageContext();
  const { setPageLoad } = usePageLoadContext();
  const { Search } = useSearchContext();
  const [ filterValue, setFilterValue ] = React.useState();
  const [ treeviewNodesRAW, setTreeviewNodesRAW ] = React.useState<TelerikTreeModel[]>([]);
  const [ treeviewNodes, setTreeviewNodes ] = React.useState<TelerikTreeModel[]>([]);
  const { Library } = useLibraryContext();
  const { setThumbsLoad } = useThumbsLoadContext();
  const { Bookmarks } = useBookmarksContext();

  const bookmarkIcon = {
    name: 'bookmark',
    content: '<svg height="155" viewBox="-20 0 160 160" width="118" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0)"><path d="M56.0783 107.382C55.4041 108.47 54.645 109.505 53.8083 110.474C41.3062 122.797 28.7751 135.09 16.2149 147.353C14.0892 149.468 11.7634 151.374 9.27033 153.043C5.04205 155.798 1.705 154.135 1.00105 149.114C0.880203 147.788 0.852448 146.455 0.917897 145.124C0.964697 113.828 0.930928 82.5317 1.11293 51.2362C1.18378 38.9257 1.66869 26.6152 2.12109 14.3105C2.32537 11.6584 2.7779 9.03129 3.47299 6.46332C4.21009 3.25696 5.09796 2.39559 8.47798 2.1245C15.3478 1.57324 22.2313 0.939644 29.1155 0.846904C54.251 0.510311 79.3897 0.402671 104.527 0.150391C107.856 0.184034 111.148 0.847844 114.229 2.10645C114.975 2.3557 115.635 2.81229 116.13 3.42268C116.625 4.03307 116.935 4.77187 117.024 5.55211C117.262 7.08419 117.366 8.63381 117.335 10.1839C115.675 53.3361 114.86 96.4946 116.072 139.676C116.054 143.084 115.767 146.486 115.213 149.848C114.88 152.368 112.483 153.852 109.719 153.48C106.933 153.093 104.387 151.697 102.565 149.56C88.7036 134.096 71.7678 122.033 56.0783 107.382ZM108.629 7.75387L10.4976 8.46337C10.3676 9.60868 10.2481 10.2502 10.2331 10.8935C10.083 17.6564 9.79098 24.4206 9.83713 31.1822C10.0789 66.5829 10.3705 101.983 10.712 137.382C10.7185 138.076 10.8115 138.769 10.8999 139.936C12.092 138.828 12.9111 138.112 13.6788 137.347C25.9464 125.103 38.2079 112.855 50.4634 100.602C54.9348 96.123 57.7733 96.0063 62.4611 100.122C68.3898 105.326 74.414 110.423 80.3179 115.656C88.7133 123.096 97.0353 130.619 105.398 138.096C105.947 138.504 106.521 138.878 107.115 139.218C104.65 95.2053 107.356 51.7531 108.629 7.75387Z" fill="#000000"/></g><defs><clipPath id="clip0"><rect fill="#000000" height="155" transform="translate(0.777344)" width="117"/></clipPath></defs></svg>',
    viewBox: '-20 0 160 160'
  };

  // Carrega as pastas na treeview
  useEffect(() => {
    const mapFolders = (folders: FoldersModel[] | null, parentPath: string): TelerikTreeModel[] => {
      if (!folders) return [];
      
      return folders.map(folder => ({
        id: folder.Id,
        color: 'black',
        text: folder.Name,
        hasSubFolders: folder.HasSubFolders,
        parent: folder.Parent,
        items: mapFolders(folder.SubFolders, parentPath + '/' + folder.Name),
        path: parentPath + (parentPath === '' ? '' : '/') + folder.Name,
        pages: folder.Pages ?? 0,
        matchs: 0,
        bookmarks: findBookmarkInFolder(folder.Id, Bookmarks) 
      }));
    }

    const _mapFolders =mapFolders(Folders,""); 
    setTreeviewNodes(_mapFolders);
    setTreeviewNodesRAW(_mapFolders);
  }, [Folders, Bookmarks]);

  const onExpandChange = (event: TreeViewExpandChangeEvent) => {
    event.item.expanded = !event.item.expanded;
  }

  // Seleciona uma pasta
  useEffect(() => {
    if (Folder?.Id != 0) {
      // Seleciona e expande o nó da treeview
      const ExpandAndSelectItem = (items: TelerikTreeModel[], id: number) => {
        if (items.length === 0) return false;
        let isSelected = false;

        items.forEach((item) => {
          item.selected = item.id === id;
          if (item.selected) isSelected = true;
          if (item.hasSubFolders) {        
            if (ExpandAndSelectItem(item.items ?? [], id)) {
              item.expanded = true;
              isSelected = true
            }
          }
        });

        return isSelected;
      };
      if (ExpandAndSelectItem(treeviewNodes, Folder?.Id ?? 0))
      {
        const PTimeToShowTreeView = 350;
        setTimeout(() => {
          const node = document.querySelector('.k-selected');
          if (node) {
            node.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }}, PTimeToShowTreeView);
      }

      // Scroll na treeview para certificar que o nó da pasta selecionada ficará visível    
      const scrollIntoView = () => {
        if (Folder?.Name) {
          let treeItemDoms = document.querySelectorAll('k-treeview');
          if (treeItemDoms.length === 0) return;
          
          treeItemDoms.forEach((treeItemDom) => {
            if (treeItemDom.textContent?.startsWith(Folder?.Name)) {
              treeItemDom.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
          });
        }
      }
      scrollIntoView();
    }
  }, [Folder])

  // Coloca as cores verde/azul e total de ocorrencias nas pastas após a pesquisa ou reseta para os icones pretos
  useEffect(() => {
    const resetTreeviewMatchs = (items: TelerikTreeModel[]) => {
      for (let item of items) {        
        item.color = 'black';
        item.matchs = 0;
        if (item.items) {
          resetTreeviewMatchs(item.items);
        }
      }
    }

    if (!Search) {   
      resetTreeviewMatchs(treeviewNodes);
    }
    else {
      const setTreeviewMatchs = (items: TelerikTreeModel[], id: number): boolean => {
        for (let item of items) {        
          if (item.id === id) {
            item.color = 'green';
            item.matchs += 1;
            return true;
          } 
          else if (Array.isArray(item.items)) {
            let found = setTreeviewMatchs(item.items, id);
            if (found) {
              if (item.matchs == 0) {
                item.color = 'blue';
                item.matchs = -1;
              }
              return found;
            }
          }          
        }

        return false;
      }
    
      resetTreeviewMatchs(treeviewNodes);

      Search?.matchs.forEach(match => {
        setTreeviewMatchs(treeviewNodes, match.folderID);
      });
    }
  }, [Search]);

  // Evento de clique no node da treeview
  const onItemClick = (event: TreeViewItemClickEvent) => {
    let folder: FoldersModel | null = findFolderById(Folders, event.item.id ?? 0);
    setFolder(folder);

    const firstMatchInFolder = Search?.matchs.find(x => x.folderID === folder?.Id);
    if (!firstMatchInFolder) {
      // Primeira página, pasta sem ocorrências
      setPage(folder?.Pages === 0 ? 0 : 1);
    }
    else {
      // Primeira pagina com ocorrência
      setPage(firstMatchInFolder?.page) 
    };

    // Por ser assincrona a atualização dos context, precisa de um Timer para dar tempo dos FolderContext e PageContext serem atualizados.
    setTimeout(() => {
      setPageLoad(true);
    }, 100);

    if (Library?.thumbs === 1) {
      setThumbsLoad(true);
    }
  };

  const renderNode = (item: ItemRenderProps) => {
    const style = {color: item.item.color, marginRight: 5, strokeWidth: 8, stroke: item.item.color};
    const styleb = {left: -25, color: item.item.color, marginRight: -15, strokeWidth: 8, stroke: item.item.color};
    const iconItem = item.item.selected ? folderOpenIcon : folderIcon;
    const text = item.item.matchs > 0 ? item.item.text + " (" + item.item.matchs + ")" : item.item.text
    
    return (
      <>
        <SvgIcon icon={iconItem} style={style} width={28} height={28} />
        {item.item.bookmarks ? <SvgIcon icon={bookmarkIcon} style={styleb}/> : <></> }
        {text}
      </>
    );
  };

  const onFilterChange = (ev: any) => {
    let term = ev.value;
    setFilterValue(term);

    let acc: any[] = [];

    const contains = (text: string, term: string) => text.toLowerCase().includes(term.toLowerCase());

    const search = (items: any[], term: string) => {
      let result: any[] = [];
      items.forEach(item => {
        if (contains(item.text, term)) {
          result.push(item);
        } else if (item.items && item.items.length > 0) {
          let newItems = search(item.items, term);
          if (newItems && newItems.length > 0) {
            result.push({
              text: item.text,
              items: newItems,
              expanded: true,
            });
          }
        }
      });
      return result;
    };

    treeviewNodesRAW.forEach(item => {
      if (contains(item.text, term)) {
        acc.push(item);
      } else if (item.items && item.items.length > 0) {
        let newItems = search(item.items, term);
        if (newItems && newItems.length > 0) {
          acc.push({
            text: item.text,
            items: newItems,
            expanded: true,
          });
        }
      }
    });

    setTreeviewNodes(acc);
  };

  return (<>
    {isMobile ?
      <div className={isVisible ? 'leftpanel-mobile' : 'folderTreeViewHidden'}>    
        <div className='leftpanelHeader-mobile'>
          <div className='leftpanelTitle-mobile'>{showMessage('pastas')}</div>   
          <div onClick={()=> setIsVisible(false)} className='leftpanelClose-mobile'>
            <span><SvgIcon className='closeSvgIcon' icon={xIcon} /> </span>  
          </div>       
          <div className='leftpanelFilter'>
            <span> <SvgIcon className='leftpanelFilterIcon' icon={filterIcon} /> </span>
            <Input className='leftpanelFilterInput' value={filterValue} onChange={onFilterChange} placeholder={showMessage("filtrar") + '...'}/>
          </div>         
        </div>
        <div className='treeviewdiv-mobile'>
          <TreeView className='k-treeview-mobile'  
            aria-multiselectable={false} 
            animate={true} 
            expandIcons={true} 
            onItemClick={onItemClick} 
            item={renderNode}
            data={processTreeViewItems(treeviewNodes, {expand: { ids: [], idField: 'text' }})}
            onExpandChange={onExpandChange} />     
        </div>
      </div>     
      :
      <div className={isVisible ? 'leftpanel' : 'folderTreeViewHidden'} >
        <div className='leftpanelHeader'>
          <div className='leftpanelTitle'>{showMessage('pastas')}</div>
          <div onClick={() => setIsVisible(false)} className='leftpanelClose'>
            <span><SvgIcon className='closeSvgIcon' icon={xIcon} /> </span>
          </div>
          <div className='leftpanelFilter' >
            <span> <SvgIcon className='leftpanelFilterIcon' icon={filterIcon} /></span>
            <Input className='leftpanelFilterInput' value={filterValue} onChange={onFilterChange} placeholder={showMessage("filtrar") + '...'}/>
          </div>
        </div>
        <div className='treeviewdiv'>
          <TreeView
            aria-multiselectable={false}
            animate={true}
            expandIcons={true}
            onItemClick={onItemClick}
            item={renderNode}
            data={processTreeViewItems(treeviewNodes, {expand: { ids: [], idField: 'text' }})} 
            onExpandChange={onExpandChange}
          />
        </div>         
      </div>
    }
    </>
  );
};
export default FoldersTreeview;