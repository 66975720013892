export const enMessages = {
  acervos: "Libraries",
  alias: "Alias",
  ate: "to",
  bookmarks: "Bookmarks",
  capas: "Covers",
  cliqueParaPesquisar: "Click to search",
  compartilharNo: "Share on",
  copypageurl: "Copy page hyperlink",
  copysearchurl: "Copy search hyperlink",
  copiedurl: "Hyperlink copied",
  descricao: "Description",
  digiteTextoParaPesquisar: "Type a text to search...",
  doctypetitle: "Metadata",
  download: "Download",
  downloads: "Downloads",
  exibir: "Show",
  exportdisablemsg: "Export disabled",
  fechar: "Close",
  fecharMenu: "Close menu",
  filtrar: "Filter",
  filtrarNomesDeBibliotecas: "Filter library names...",
  gerar: "Generate",
  incluirSubPastas: "Include subfolders",
  incluirSubpastas: "Include subfolders",
  info: "Info",
  informacoes: "Informations",
  librarynotfound: "Library not found",
  limpaaPesquisa: "Clear search",
  miniaturas: "Thumbnails",
  miniaturasCapas: "Thumbnails of covers",
  miniaturasMarcadas: "Marked thumbnails",
  miniaturasOcorrencias: "Thumbnails of occurrences",
  naoExistePaginas: "There are no pages to generate a PDF",
  nome: "Name",
  ocorrencias: "Matchs",
  ocultar: "Hide",
  pagina: "Page",
  paginas: "Pages",
  paginasDe: "Pages from",
  pastas: "Folders",
  pesquisarNasBibliotecas: "Search in libraries...",
  pesquisar: "Search",
  pressioneEnterParaPesquisar: "Press ENTER to search",
  qualidade: "Quality",
  semOcorrencias: "No matchs",
  todaAPastaAtual: "All current folder",
  todasOcorrencias: "All occurrences",
  todasOcorrenciasPastaAtual: "Occurrences of the current folder",
  totalDePaginas: "Total pages",
  totalDePastas: "Total folders",
  zoom: "Zoom",
};
